<template>
    <BT-Blade-Items
        addBladeName="product-category"
        bladeName="product-categories"
        :bladesData="bladesData"
        canAdd
        canDelete
        canSearchLocal
        :headers="[
            { text: 'Category Name', value: 'categoryName', title: 1, searchable: true },
            { text: 'Category Description', value: 'categoryDescription', title: 1, searchable: true }]"
        navigation="product-categories"
        title="Product Categories"
        useServerPagination />
</template>

<script>
export default {
    name: 'Product-Categories',
    props: {
        bladesData: null
    }
}
</script>